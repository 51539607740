@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gordita';
  src: url('./gordita/Gordita Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gordita';
  src: url('./gordita/Gordita Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gordita';
  src: url('./gordita/Gordita Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gordita';
  src: url('./gordita/Gordita Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.main-banner {
  @apply text-4xl;
}

.button-large {
  @apply bg-main-color text-white text-4xl font-bold px-8 pt-4 pb-2.5 rounded-full transition-all duration-300
    hover:bg-main-color/90
    active:bg-main-color/70;
}

.button-small {
  @apply bg-main-color text-white text-lg font-bold px-8 pt-2 pb-1.5 rounded-full transition-all duration-300
    hover:bg-main-color/90
    active:bg-main-color/70;
}

.button-small-light {
  @apply bg-white text-lg text-black/80 font-bold px-8 pt-2 pb-1.5 rounded-full transition-all duration-300
    hover:bg-white/90
    active:bg-white/70;
}

.content-block {
  @apply py-24;
}

.header-text {
  @apply text-xl font-bold text-main-color;
}

.header-text-white {
  @apply text-xl font-bold text-white;
}

.input-field {
  @apply rounded-2xl py-2 px-4 placeholder:text-gray-300 my-1 focus:outline-none text-black;
}

.input-field-small {
  @apply rounded-lg pt-1 pb-0.5 px-3 placeholder:text-gray-300 focus:outline-none text-black text-sm border border-main-color;
}

.animate {
  @apply transition-all duration-300 ease-in-out;
}

a {
  @apply underline hover:font-semibold text-main-color;
}
